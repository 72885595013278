import React, { useState } from "react";

const ThemeEditor = () => {
  const [primaryColor, setPrimaryColor] = useState("#F2F46C");
  const [secondaryColor, setSecondaryColor] = useState("#6CF4EC");
  const [headerFont, setHeaderFont] = useState("Roboto");
  const [bodyFont, setBodyFont] = useState("Poppins");
  const [headerFontSize, setHeaderFontSize] = useState(40);
  const [bodyFontSize, setBodyFontSize] = useState(12);
  const [headerFontStyle, setHeaderFontStyle] = useState("Bold");
  const [bodyFontStyle, setBodyFontStyle] = useState("Regular and Italic");

  return (
    <div className="theme-editor-main" style={{ fontFamily: "Arial, sans-serif", padding: "0px 20px" }}>
      <div className="theme-editor-heading">
      <h2>Layout Theme Colors</h2>
      </div>

      <div className="theme-editor-color-palet-section" style={{ display: "flex", gap: "20px", marginBottom: "20px" }}>
        <div className="theme-editor-primary-color-wrapper"> 
          <label>Primary Color</label>
          <span>{primaryColor}</span>
          <input
            type="color"
            value={primaryColor}
            onChange={(e) => setPrimaryColor(e.target.value)}
           
          />
        </div>
        <div className="theme-editor-secondary-color-wrapper">
          <label>Secondary Color</label>
          <span>{secondaryColor}</span>
          <input
            type="color"
            value={secondaryColor}
            onChange={(e) => setSecondaryColor(e.target.value)}
          />
        </div>
      </div>

    <div className="theme-editor-select-fonts">
            <div className="select-font-heading">
            <h2>Select Fonts</h2>
            </div>
        <div className="select-fonts-wrapper" style={{ display: "flex", gap: "20px", marginBottom: "20px" }}>
            <div className="select-fonts-header">
            <label>Header</label>
            <select
                value={headerFont}
                onChange={(e) => setHeaderFont(e.target.value)}
            >
                <option value="Roboto">Roboto</option>
                <option value="Arial">Arial</option>
                <option value="Helvetica">Helvetica</option>
            </select>
            </div>
            <div className="select-fonts-body">
            <label>Body</label>
            <select
                value={bodyFont}
                onChange={(e) => setBodyFont(e.target.value)}
            >
                <option value="Poppins">Poppins</option>
                <option value="Times New Roman">Times New Roman</option>
                <option value="Verdana">Verdana</option>
            </select>
            </div>
        </div>
    </div>
    <div className="theme-editor-select-fonts-size">
    <div className="select-font-heading">
      <h2>Select Font Size</h2>
      </div>
      <div className="select-fonts-size-wrapper" style={{ display: "flex", gap: "20px", marginBottom: "20px" }}>
        <div className="select-fonts-size-header">
          <label>Header</label>
          <input
            type="number"
            value={headerFontSize}
            onChange={(e) => setHeaderFontSize(e.target.value)}
            style={{ display: "block", width: "150px", height: "30px" }}
          />
        </div>
        <div className="select-fonts-size-body">
          <label>Body</label>
          <input
            type="number"
            value={bodyFontSize}
            onChange={(e) => setBodyFontSize(e.target.value)}
            style={{ display: "block", width: "150px", height: "30px" }}
          />
        </div>
      </div>
      </div>

      <div className="theme-editor-select-fonts">
            <div className="select-font-heading">
            <h2>Select Fonts Style</h2>
            </div>
        <div className="select-fonts-wrapper" style={{ display: "flex", gap: "20px", marginBottom: "20px" }}>
            <div className="select-fonts-header">
            <label>Header</label>
            <select
                value={headerFont}
                onChange={(e) => setHeaderFont(e.target.value)}
            >
                <option value="Itelic">Itelic</option>
                <option value="Bold">Bold</option>
                <option value="Semi Bold">Semi Bold</option>
            </select>
            </div>
            <div className="select-fonts-body">
            <label>Body</label>
            <select
                value={bodyFont}
                onChange={(e) => setBodyFont(e.target.value)}
            >
                <option value="Itelic">Itelic</option>
                <option value="Bold">Bold</option>
                <option value="Semi Bold">Semi Bold</option>
            </select>
            </div>
        </div>
    </div>
    </div>
  );
};

export default ThemeEditor;
