import React from 'react'
import Header from '../../../components/Layout/Header'
import SuplierStoreTop from '../SuplierStore/SuplierStoreTop'
import SuplierProductTop from './SuplierProductTop'
import Footer from '../../../components/Layout/Footer'

function SuplierStoreProductPage() {
  return (
    <div>
        <Header/>
        <SuplierStoreTop/>
        <SuplierProductTop/>
        <Footer/>
    </div>
  )
}

export default SuplierStoreProductPage
