import React from 'react'
import ProductCard from './ProductCard'
import group from '../../media/images/Group.svg'
import StoreCard from './StoreCard'
import DonationCard from './DonationCard'
import donation from '../../media/images/Tem_Images/donationCard.svg'
import pat from '../../media/images/Tem_Images/pat.svg'
import { Link } from 'react-router-dom'

function DonateForHuman() {
  return (
    <>
        <div className='container'>
        <div className="donation-section">
          <div className="row">
          
            <div className="product-section-title d-flex justify-content-space-between align-item-center">
                  <div className="col-lg-11 d-flex justify-content-left gap-20">
                    <img src={group} /> <h1>Donate For Humans In Need</h1>
                  </div>
              <div className="col-lg-1">
              <div className="view-all-buttons">
                <Link to='/donation-detail'><p>View All</p></Link>
              </div>
              </div>
            </div>


            <div className="row">
                <div className="col-lg-3">
                    <DonationCard image={donation}/>
                </div>
                <div className="col-lg-3">
                    <DonationCard image={pat}/>
                </div>
                <div className="col-lg-3">
                    <DonationCard image={donation}/>
                </div>
                <div className="col-lg-3">
                    <DonationCard image={pat}/>
                </div>
            </div>
          </div>
        </div>
      </div >
    </>
  )
}

export default DonateForHuman
