import React from 'react'
import { Link } from 'react-router-dom'

function FundingNav() {
    return (
        <div className='funding-nav-main'>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="fund-nav">
                           
                                <ul className='sec-ul-one'>
                                    <li>
                                        <Link to="/funding-cycle" >Home</Link>
                                    </li>
                                    <li>
                                        <Link to="">Campaigns</Link>
                                    </li>
                                    <li>
                                        <Link to="/donation-event">Events</Link>
                                    </li>

                                </ul>
                          
                          
                                <ul className="sec-ul-two">
                                    <li>
                                        <Link to="">Donate Now</Link>
                                    </li>
                                    <li>
                                        <Link to="">Become a Volunteer</Link>
                                    </li>
                                    

                                </ul>
                            
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default FundingNav
