import React from 'react'
import HeaderTop from '../../../../components/Layout/HeaderTop'
import ServiceDashboardSectionTwo from './ServiceDashboardSectionTwo'
import Footer from '../../../../components/Layout/Footer'

function ServiceDashboardMain() {
  return (
    <div>
      <HeaderTop/>
      <ServiceDashboardSectionTwo/>
      <Footer/>


    </div>
  )
}

export default ServiceDashboardMain
