import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import signuplogo from '../media/images/logo-sign-up.png'
import eye from '../media/images/eye.svg'
import eyeHide from '../media/images/eyeHide.svg'

function NewPassword() {
    const [formData, setFormData] = useState({
        newPassword: '',
        confirmPassword: '',
    });
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowNewPassword((prevShowNewPassword) => !prevShowNewPassword);
    };

    const togglePasswordVisibility2 = () => {
        setShowConfirmPassword((prevShowConfirmPassword) => !prevShowConfirmPassword);
    };
    const navigate = useNavigate();  // Declare navigate hook here

    // Handler to update state on input change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // Handler for form submission (you can expand this as needed)
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form Data:', formData);
        navigate("/opt")
    };
    return (
        <div className='sign-up'>
            <div className='sign-form-fgp' >
                <div className='form-wrapper'>
                    <div className='sign-up-image'>
                    <Link to='/'><img src={signuplogo} /></Link>
                    </div>
                    <form onSubmit={handleSubmit} className='form-main-forgot-password'>
                    <div style={{  position: 'relative'  }}>
                            <p>New Password</p>
                            <input
                                type={showNewPassword ? "text" : "password"}
                                name="newpassword"
                                placeholder='*************'
                                value={formData.newpassword}
                                onChange={handleChange}
                                required
                            />
                            <img
                            src={showNewPassword ? eye : eyeHide}
                                className={`fas ${showNewPassword ? 'fa-eye-slash' : 'fa-eye'}`}  // Font Awesome eye icon
                                onClick={togglePasswordVisibility}
                                style={{
                                    width: "35px",
                                    position: 'absolute',
                                    right: "1.5rem",
                                    top: "4.8rem",       
                                    transform: 'translateY(-50%)',
                                    cursor: 'pointer',
                                    color: '#888',
                                }}
                            />
                        </div>
                        <div style={{  position: 'relative'  }}>
                            <p>Confirm Password</p>
                            <input
                                type={showConfirmPassword ? "text" : "password"}
                                name="confirmpassword"
                                placeholder='*************'
                                value={formData.confirmpassword}
                                onChange={handleChange}
                                required
                            />
                            <img
                            src={showConfirmPassword ? eye : eyeHide}
                                className={`fas ${showConfirmPassword ? 'fa-eye-slash' : 'fa-eye'}`}  // Font Awesome eye icon
                                onClick={togglePasswordVisibility2}
                                style={{
                                    width: "35px",
                                    position: 'absolute',
                                    right: "1.5rem",
                                    top: "4.8rem",                                
                                    transform: 'translateY(-50%)',
                                    cursor: 'pointer',
                                    color: '#888',
                                }}
                            />
                        </div>

                    </form>
                    <div className='sign-up-form-New-password-button'>
                            <button type="submit">Confirm</button>
                        </div>
                </div>
            </div>
        </div>
    )
}

export default NewPassword
