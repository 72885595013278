import React from 'react'
import Header from '../../../components/Layout/Header'
import ServiceProviderDropdowns from '../ServiceProviderFlow/ServiceProviderDropdowns'
import ServiceProviderCardsSection from '../ServiceProviderFlow/ServiceProviderCardsSection'
import RelatedSearchServiceProvider from '../ServiceProviderFlow/RelatedSearchServiceProvider'
import ServiceProviderBrowserSearch from '../ServiceProviderFlow/ServiceProviderBrowserSearch'
import Footer from '../../../components/Layout/Footer'
import ServiceCatagoryCardSection from './ServiceCatagoryCardSection'
import { useParams } from 'react-router-dom';

function ServiceCatagoryDetailPage() {
  const { tab } = useParams();

  return (
    <>
     <Header/>
     <ServiceProviderDropdowns/>
     <ServiceCatagoryCardSection tab={tab}/>
     <RelatedSearchServiceProvider />
     <ServiceProviderBrowserSearch/>
     <Footer/>
    </>
  )
}

export default ServiceCatagoryDetailPage
