import React from 'react'
import icon from '../../media/images/Tem_Images/Multiple Devices.svg'

function TopCatagoriesCard() {
  return (
    <div className='top-cat-card'>
        <div className='top-cat-card-wrapper'>    
        <div className='top-cat-card-icon'>
            <img src={icon} />
        </div>
        <div className='top-cat-card-title'>
            <h3>Consumer Electronics</h3>
        </div>
      </div>
    </div>
  )
}

export default TopCatagoriesCard
