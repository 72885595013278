import React from 'react'
import HeaderTop from '../../../components/Layout/HeaderTop'
import FundingCycleHeader from '../FundingCycleHeader'
import HeaderNavbar from '../../../components/Layout/HeaderNavbar'
import DonationBannerSec from '../DonationBannerSec'
import FundingNav from '../FundingNav'
import FundAboutOrganization from './FundAboutOrganization'
import DonationFundingCycleSec from './DonationFundingCycle3rdSec'
import Footer from '../../../components/Layout/Footer'
import SliderThreeDetailPage from '../../Feature Product/ProductDetail/slider_other-recomendations/SliderThreeDetailPage'
import { Link } from 'react-router-dom'

function FundingCycle() {
  return (
    <div>
         <HeaderTop/>
         <HeaderNavbar/>
         <FundingCycleHeader/>
         <DonationBannerSec/>
         <FundingNav/>
        <FundAboutOrganization/>
        <DonationFundingCycleSec/>
        <div className="container">
        <div className="sliderThree">
          <div className="row">
            <div className="col-lg-6">
            <div className="funding-cycle-donation-card-title">
                <h2>Campaigns</h2>
            </div>
            </div>
            <div className="col-lg-6">
              <div className="funding-cycle-view-all-btn">
            <Link to="/funding-cycle-all" style={{textDecoration:"none"}} ><button style={{
              color:"#018bc3",
              background:"none",
              border:"none",
              margin:"25px 10px 0px"
            }}>View All</button></Link>  
              </div>
            </div>
          </div>
                <SliderThreeDetailPage />
            </div>
        </div>
            <Footer/>
      
    </div>
  )
}

export default FundingCycle
