import React from 'react'
import Header from '../../../components/Layout/Header'
import ServiceSellerProfileTop from './ServiceSellerProfileTop'
import ServiceSellerGallery from './ServiceSellerGallery'
import AboutMethew from './AboutMethew'
import GetToKnowAbtMethew from './GetToKnowAbtMethew'
import OtherRecomendationsForSeller from './OtherRecomendationsForSeller'
import Footer from '../../../components/Layout/Footer'

function ServiceSellProfile() {
  return (
    <div>
        <Header/>
        <ServiceSellerProfileTop/>
        <ServiceSellerGallery/>
        <AboutMethew/>
        <GetToKnowAbtMethew/>
        <OtherRecomendationsForSeller/>
        <Footer/>
    </div>
  )
}

export default ServiceSellProfile
