import React from 'react'
import storeLogo from '../../media/images/Tem_Images/storeLogo.svg'
import Arrow from '../../media/images/Arrow 2.svg'
import star from '../../media/images/star.svg'

function StoreCard({image , cat}) {
  return (
    <div className='store-card'>
        <img src={image} />
      <div className='store-card-arow'>
        <img src={Arrow} />
      </div>
      <div className='store-card-logo-section'>
        <div className='store-card-logo'>
            <img src={storeLogo} />
        </div>
        <div className='store-card-content'>
            <h1>Fashion Repair Service</h1>
            <h4>Category: {cat}</h4>
        </div>
        <div className='store-card-rating'>
        <img src={star} /><h3> 4.3</h3>
        </div>
      </div>
    </div>
  )
}

export default StoreCard
