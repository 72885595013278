import React from 'react'

function AboutMethew() {
    return (
        <div className='container'>
            <div className="horizontal-line-service-profile-page">
                <hr></hr>
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <div className="about-user-title">
                        <h2>About Mathew</h2>
                    </div>
                    <div className="about-user-list">
                        <p>My Experiences Include</p>
                        <ul>
                            <li>Proficiency in Adobe Creative Suite (Photoshop, Illustrator, InDesign)</li>
                            <li>Strong understanding of typography, color theory, and composition</li>
                            <li>Ability to create visually appealing logos, illustrations, and layouts</li>
                            <li>Experience with both print and digital design projects</li>
                            <li>Knowledge of industry-standard design principles and trends</li>
                            <li>Excellent communication and collaboration skills</li>
                            <li>Time management and ability to meet deadlines consistentlyc</li>
                            <li>Flexibility and willingness to adapt to project requirements</li>
                            <li>Attention to detail and commitment to quality assurance</li>
                            <li>Problem-solving skills to address design challenges effectively</li>
                        </ul>
                        <p>and much more</p>
                    </div>
                </div>
                <div className="col-lg-6"></div>
            </div>
            <div className="horizontal-line-service-profile-page">
                <hr></hr>
            </div>

        </div>
    )
}

export default AboutMethew
