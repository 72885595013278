import React, { useState } from "react";

const Counter = () => {
  const [count, setCount] = useState(1); // Initial count set to 4

  const increment = () => setCount(count + 1);
  const decrement = () => count > 0 && setCount(count - 1);

  return (
    <div className="counter-container">
      <button className="counter-btn" onClick={decrement}>
        −
      </button>
      <span className="counter-value">{count.toString().padStart(2, "0")}</span>
      <button className="counter-btn" onClick={increment}>
        +
      </button>
    </div>
  );
};

export default Counter;
