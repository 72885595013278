import React from 'react'
import donation from '../../media/images/Tem_Images/donationCard.svg'
import { Link } from 'react-router-dom'

function DonationCard({image}) {
  return (
    <div className='Donation-card'>
        <div className='donation-card-image'>
            <img src={image} />
        </div>
        <div className='donation-card-title'>
            <h1>Food Drive in Wiscosin By Feed the Children</h1>
        </div>
        <div className='donation-card-content'>
            <p>FashionForAll is a platform that helps to make fashion accessible to all. It brings fashion to your doorstep!</p>
        </div>
        <div class="progress-bar-container">
        <div class="progress-bar-fill"></div>
        <div class="progress-bar-light-fill"></div>
        </div>        
        <div className='donation-card-price'>
            <div className='donation-card-price-tag-one'>
                <h1>$ 450.50</h1>
                <h4>Raised So far</h4>
            </div>
            <div className='donation-card-price-tag-two'>
            <h1>$ 450.50</h1>
            <h4>Amount Required</h4>
            </div>
        </div>
        <div className='donation-card-button'>
        <div className='service-card-button'>
           <Link to="/donation-food-drive" > <button>Donate Now</button></Link>
            </div>
        </div>
    </div>
  )
}

export default DonationCard
