import React from 'react';
import HeaderTop from './HeaderTop';
import HeaderNavbar from './HeaderNavbar';

function Header() {
    return (
        <>
            <HeaderTop />     
            <HeaderNavbar />
        </>
    );
}

export default Header;
