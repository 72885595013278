import React from 'react'
import HeaderTop from '../../../../components/Layout/HeaderTop'
import Footer from '../../../../components/Layout/Footer'
import AddQuestionSectionTwo from './AddQuestionSectionTwo'

function AddFrequentlyQuestions() {
  return (
    <div>
            <HeaderTop/>
            <AddQuestionSectionTwo/>
            <Footer />
    </div>
  )
}

export default AddFrequentlyQuestions
