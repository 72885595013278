import React from 'react'
import star from '../../media/images/star.svg'

function ServicesCard({image}) {
  return (
    <div className='service-card'>
      <div className='service-card-image'>
        <img src={image} />
        <div className='service-card-content'>
            <div className='service-card-title-rating'>
                <h1>Banner Printing</h1>
                <div className='service-card-rating'>
                <img src={star} />
                <h3> 4.3</h3>
                </div>
            </div>
            <h2>Categories : Design Services</h2>
            <p>FashionForAll is a platform that helps to make fashion accessible to all. It brings fashion to your doorstep!</p>
            <div className='service-card-button'>
            <button>Get Service</button>
            </div>
        </div>
      </div>
    </div>
  )
}

export default ServicesCard
