import React from 'react'
import Footer from '../../../components/Layout/Footer'
import Header from '../../../components/Layout/Header'
import FoodDriveFirstSec from './FoodDriveFirstSec'

function DonationFoodDrive() {
  return (
    <div>
                <Header/>
                <FoodDriveFirstSec/>
                <Footer/>
      
    </div>
  )
}

export default DonationFoodDrive
