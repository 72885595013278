import React from 'react'
import group from '../../media/images/Group.svg'
import ServicesCard from './ServicesCard'
import service from '../../media/images/Tem_Images/service.svg'
import buss from '../../media/images/Tem_Images/buss.svg'
import { Link } from 'react-router-dom'


function TrendingService() {
  return (
    <>
        <div className='container'>
        <div className="product-section">
          <div className="row">
            
            <div className="product-section-title d-flex justify-content-space-between align-item-center" style={{paddingTop: "6rem"}}>
                <div className="col-lg-11 d-flex justify-content-left gap-20">
                  <img src={group} /> <h1>Trending Services</h1>
                </div>
              <div className="col-lg-1">
              <div className="view-all-buttons">
                <Link to="/service-detail"><p>View All</p></Link>
              </div>
              </div>
            </div>



            <div className="row">
              <div className="col-lg-3">
                  <ServicesCard image={service}/>
              </div>
              <div className="col-lg-3">
                  <ServicesCard image={buss}/>
              </div>
              <div className="col-lg-3">
                  <ServicesCard image={service}/>
              </div>
              <div className="col-lg-3">
                  <ServicesCard image={buss}/>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  )
}

export default TrendingService
