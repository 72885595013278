import React from 'react'
import { Link } from 'react-router-dom'

export default function SingleServiceCustomTabs({tab}) {
  return (
  <>
    <Link to={`/serviceCatagory/${tab}`} style={{textDecoration:"none"}}>
    <div className='single-service-tab'>
        <p>{tab}</p>
    </div></Link>
  </>    

)
}
