import React from 'react'
import ProductCard from './ProductCard'
import group from '../../media/images/Group.svg'
import StoreCard from './StoreCard'
import cardImage from '../../media/images/Tem_Images/store.svg'
import soffa from '../../media/images/Tem_Images/soffaa.svg'
import { Link } from 'react-router-dom'


function TopRatedStores() {
  return (
    <>
      <div className='container'>
        <div className="product-section">
          <div className="row">
            <div className="product-section-title d-flex justify-content-space-between align-item-center">
              <div className="col-lg-11 d-flex justify-content-left gap-20">
                <img src={group} /> <h1>Top Rated Stores</h1>
              </div>
              <div className="col-lg-1">
              <div className="view-all-buttons">
                <Link to='/store-detail'><p>View All</p></Link>
              </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                  <StoreCard image={soffa} cat={"faishon1"}/>
              </div>
              <div className="col-lg-4">
                  <StoreCard image={cardImage} cat={"faishon2"}/>
              </div>
              <div className="col-lg-4">
                  <StoreCard image={soffa} cat={"faishon3"}/>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  )
}

export default TopRatedStores
