import React from 'react'
import beachbanner from '../../../media/images/beach-banner.png'

function DonationBeachFirstSec() {
    return (
        <>
            <div className='beach-first-sec'>
                <img src={beachbanner} alt="" />
                <div className="container">
                    <div className="row align-items-center mt-5" >
                        <div className="col-md-9">

                            <h1>Beach Clean-Up Challenge</h1>
                            <p>
                                Updated on June 6, 2024
                            </p>

                        </div>
                        <div className="col-md-3">
                            <div className="beachhead-sec2">
                                <h2>
                                    110
                                </h2>
                                <p>Volunteers</p>
                            </div>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-10">

                            <div className="secbeach2-text">
                                <h3>
                                    Program Overview
                                </h3>
                                <p>The Beach Clean-Up Challenge is a community-driven event aimed at collecting trash and recyclables from a local beach.
                                    Participants work together to clean up the beach, helping to preserve the environment and promote ocean
                                    conservation. Donations are pledged per pound of waste collected, with all proceeds supporting ocean conservation efforts.</p>
                            </div>
                            <div className="secbeach2-text">
                                <h3>
                                    Objective
                                </h3>
                                <ul>
                                    <li>
                                        To engage the community in environmental conservation efforts.</li>
                                    <li>To remove trash and recyclables from the beach, making it cleaner and safer.</li>
                                    <li>To raise funds for ocean conservation initiatives through pledged donations.</li>

                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-8">
                            <div className="secbeach2-text">
                                <h3>
                                    Date and Time
                                </h3>

                                <ul>
                                    <li><b>Date: </b>August 15, 2024</li>
                                    <li><b>Time: </b>9:00 AM - 1:30 PM</li>
                                </ul>



                            </div>

                        </div>


                        <div className="col-md-4">
                            <div className="beach-datetime">
                                <h4>Become a Volunteer</h4>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </>
    )
}

export default DonationBeachFirstSec
