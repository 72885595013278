import React from 'react'
import HeaderTop from '../../../../components/Layout/HeaderTop'
import AddServiceSectionTwo from './AddServiceSectionTwo'
import Footer from '../../../../components/Layout/Footer'

function AddService() {
  return (
    <div>
            <HeaderTop/>
            <AddServiceSectionTwo/>
            <Footer/>

    </div>
  )
}

export default AddService
