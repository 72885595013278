import React from 'react'
import Header from '../../../components/Layout/Header'
import FundingCycleHeader from '../FundingCycleHeader'
import DonationBannerSec from '../DonationBannerSec'
import FundingNav from '../FundingNav'
import DonationBeachFirstSec from './DonationBeachFirstSec'
import Footer from '../../../components/Layout/Footer'

function DonationBeachClean() {
  return (
    <div>
      <Header/>
      <FundingCycleHeader funding={"false"}/>
      <DonationBannerSec/>
      <FundingNav/>
      <DonationBeachFirstSec/>
      <Footer/>
    </div>
  )
}

export default DonationBeachClean
