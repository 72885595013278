import React from 'react'
import image from '../../media/images/🤖 AI Generated Avatars_ Amir Fakhri.png'
import arg from '../../media/images/Argentina (AR).png'
import mask from '../../media/images/Mask group.png'
import mask2 from '../../media/images/Rectangle 17922.png'
import stars from '../../media/images/stars.png'
import time from '../../media/images/subway_time-3.png'
import { Link } from 'react-router-dom'

function FeatureSupplierCards() {
    return (
        <div className='container'>
            <div className="supplier-card-main">
                <div className="supplier-card-top-sec">
                    <div className="supplier-card-name-plate d-flex">
                        <div className="supplier-profile-image">
                            <img src={image} />
                        </div>
                        <div className="supplier-profile-name-city">
                            <h2>Zhejiang Yonjou Technology Ltd.</h2>
                            <h5><img src={arg} className='supplier-flag-image' /> 07 Years</h5>
                        </div>
                    </div>
                    <div className="supplier-card-buttons">
                        <div class="f-service-detail-page-top-button">
                            <button style={{fontSize: "12px"}}>Contact</button>
                        </div>
                        <div class="f-service-detail-page-top-button">
                           <Link to="/SuplierStore" ><button style={{fontSize: "12px"}}>View Store</button></Link> 
                        </div>
                    </div>
                </div>
                <div className="supplier-card-bottm-sec">
                    <div className="supplier-card-rating-main">
                        <div className="supplier-rating">
                            <h3>Rating and Review <img src={stars} /></h3>
                            <p>4.8/5  <span>(215 Reviews)</span></p>
                        </div>
                        <div className="supplier-response">
                            <h3>Response Time <img src={time} /></h3>
                            <p>02/ Hours</p>
                        </div>
                        <div className="supplier-deals">
                            <h3>Deals In</h3>
                            <p>Headphones, Microphone, Airpods, Chargers</p>
                        </div>
                    </div>
                    <div className="supplier-card-images">
                        <div className="row">
                            <div className="col-lg-3">
                                <img src={mask} />
                            </div>
                            <div className="col-lg-3">
                            <img src={mask} />
                            </div>
                            <div className="col-lg-6">
                            <img src={mask2} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FeatureSupplierCards
