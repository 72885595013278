import React, { useState } from "react";
import arr from "../../../media/images/rarrow.png"
import { Link } from "react-router-dom";
const FoodSideCompo = () => {
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [customAmount, setCustomAmount] = useState("");

  const donationAmounts = [30, 40, 50, 70, 90];

  const handleCustomAmountChange = (e) => {
    setCustomAmount(e.target.value);
    setSelectedAmount(null);
  };

  const handleSelectAmount = (amount) => {
    setSelectedAmount(amount);
    setCustomAmount(""); 
  };

  return (
    <div className="donation-food-sidebar">
      <div className="side-progress-section">
        <h1 className="food-raised-amount">
          $199,269 <span>raised of $5,000,000 goal</span>
        </h1>
        <div class="side-progress-bar-container">
        <div class="side-progress-bar-fill"></div>
        <div class="side-progress-bar-light-fill"></div>
        </div> 
       <Link to="/donate-form" style={{textDecoration:"none"}} ><button className='side-donate-btn'>Donate Now</button></Link> 
        <div className="side-progress-info">
          <p>785 donations</p>
          <p>$4,700,751 to go</p>
        </div>
      </div>

      <div className="side-donation-options">
        {donationAmounts.map((amount) => (
          <div
            key={amount}
            className={`side-donation-option ${
              selectedAmount === amount ? "selected" : ""
            }`}
            onClick={() => handleSelectAmount(amount)}
          >
            <h2>${amount}</h2>
            <p>Emergency Food, Supplies, And Long-Term Aid</p>
            <span className="arrow"><img src={arr} /></span>
          </div>
        ))}

       <div className="side-donation-buttons">
        <button className="side-donate-once">Donate Once</button>
        <button className="side-donate-monthly">Donate Monthly</button>
      </div>

      </div>

       <div className="text-become-vol">
      <Link to="/become-a-volunteer" style={{textDecoration:"none"}}><h2>Become a Volunteer</h2></Link> 
       </div>

      

     
    </div>
  );
};

export default FoodSideCompo;