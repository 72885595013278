import React from 'react'
import rela from '../../media/images/rela.png'
function RelatedCardSearch() {
  return (
    <div className='feature-related-card'>
      <img src={rela} />
      <p>Lorem ipsum dolor sit amet consectetur. Quis arcu non morbi tincidunt in. At erat fames metus viverra id lectus aliquet.</p>
    </div>
  )
}

export default RelatedCardSearch
