import React, { useEffect, useRef, useState } from 'react';
import humb from '../../media/images/humb.svg';
import blackAero from '../../media/images/Vector (1).png';
import bags from '../../media/images/Tem_Images/Rectangle 17795.svg';
import { Link } from 'react-router-dom';

function HeaderNavbar() {
    const [dropdownActive, setDropdownActive] = useState(false);
    const dropdownRef = useRef(null);
    const buttonRef = useRef(null); // Reference for the dropdown button

    const handleDropdown = () => {
        // If dropdown is open, close it; otherwise, open it
        if (dropdownActive === true) {
            setDropdownActive(false);
        } else {
            setDropdownActive(true);
            console.log("true check dpdown")
        }
    };

    // Close dropdown if clicking outside of it
    useEffect(() => {
        function handleClickOutside(event) {
            // Check if click is outside the dropdown and not on the button
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target) &&
                buttonRef.current &&
                !buttonRef.current.contains(event.target)
            ) {
                setDropdownActive(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className='nav-bar'>
            <div className="container">
                <div className='nav-bar-flex-class'>
                    <div className='nav-bar-nav-items'>
                        <ul>
                            <li className="dropdown" onClick={handleDropdown}  ref={buttonRef} >
                                <img src={humb} alt="Menu" /> All Categories
                            </li>
                            <li>Home</li>
                            <Link to='/donation-now'>  <li>Donate now</li></Link>
                            <Link to='/featureProduct'> <li>Featured Products</li> </Link>
                           <Link to='/service'> <li>Services</li> </Link>
                        </ul>
                    </div>
                    <div className='nav-bar-nav-links'>
                        <ul>
                            <li>Get Donation</li>
                            <Link to='/become-seller' style={{textDecoration:"none", color:"white"}}><li>Become a Seller</li></Link> 
                        </ul>
                    </div>
                </div>
                <div
                    ref={dropdownRef}  // Attach ref to dropdown div
                    className={`Allcatagories-dropdown ${dropdownActive ? 'active' : 'close'}`}
                >
                    <div className="row">
                        <div className="col-lg-3">
                            <div className='dropdown-main-catagories'>
                                <ul>
                                    <li className='active'>All Product</li>
                                    <li>Our Service</li>
                                    <li>Donations</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className='dropdown-sub-catagories'>
                                <ul>
                                    <li className='active'>Fashion Accessories</li>
                                    <li>Sports & Outdoors</li>
                                    <li>Fashion Accessories</li>
                                    <li>Sports & Outdoors</li>
                                    <li>Fashion Accessories</li>
                                    <li>Sports & Outdoors</li>
                                    <li>Fashion Accessories</li>
                                    <li>Sports & Outdoors</li>
                                    <li>Fashion Accessories</li>
                                    <li>Sports & Outdoors</li>
                                    <li>Fashion Accessories</li>
                                    <li>Sports & Outdoors</li>
                                    <li>Fashion Accessories</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className='dropdown-catagories-product'>
                                <div className='dropdown-catagories-products-section'>
                                    <h1>Fashion Accessories <img src={blackAero} alt="Arrow" /></h1>
                                    <div className='all-products-subSection-three'>
                                        {[...Array(15)].map((_, i) => (
                                            <div className='dropdown-catagory-product' key={i}>
                                                <img src={bags} alt="Product" />
                                                <p>Handbags</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HeaderNavbar;
