import React from 'react'
import svglock from '../../media/images/game-icons_locked-heart.svg'
import { Link } from 'react-router-dom'


function PackageSeller() {
  return (
    <div className='become-seller-packages'>
      <div className="container">
        <div className="row">
            <div className="col-md-12">
                <div className="become-seller-package-head">
                    <h1>
                    Packages
                    </h1>
                    <p>You need to buy Package before become a seller.</p>
                </div>
            </div>
        </div>

        <div className="cards-main-become-seller">
          <div className="row">
            <div className="col-md-4">
              <Link to="/buy-package" ><div className="become-card">
                <div className="text-become-price">
                  <h2>$50.00<span style={{fontSize:"20px",fontWeight:"400",}}>/Month</span></h2>
                </div>
                 <h1 id='first-upto'>Up To <b>50 Orders</b>/Month</h1>
              </div></Link>
              <div className="level-tag-packages-page">
                <h3>Level 1</h3>
              </div>
            </div>
            <div className="col-md-4">
              <div className="become-card">
                <div className="text-become-price">
                  <h2>$50.00<span style={{fontSize:"20px",fontWeight:"400",}}><br />+<br/>7% Per Order</span></h2>
                </div>
                <div className="lock-heart"><p>Locked<img src={svglock} alt="" srcset="" /></p></div>
                 <h1><b>50+ orders</b>/Month</h1>
              </div>
              <div className="level-tag-packages-page">
                <h3>Level 2</h3>
              </div>
            </div>
            <div className="col-md-4">
              <div className="become-card">
                <div className="text-become-price">
                  
                <h2>$50.00<span style={{fontSize:"20px",fontWeight:"400",}}><br />+<br/>10% Per Order</span></h2>
                </div>
                <div className="lock-heart"><p>Locked<img src={svglock} alt="" srcset="" /></p></div>
                <h1>Will be eligible after<b><br/>200 orders</b></h1>
              </div>
              <div className="level-tag-packages-page">
                <h3>Level 3</h3>
              </div>
            </div>
          </div>
        </div>
        </div>      
    </div>
  )
}

export default PackageSeller
