import React from 'react'
import DomainCardImg from '../../media/images/mens.svg'
import { Link } from 'react-router-dom'

function SelectYourDomainCards() {
  return (
    <div className='select-your-domain-card-section'>
       <div className="container">
        <div className="row justify-content-center">
            <div className="col-md-7">
            <div className="select-your-domain-card-txt-sec">
             <p>
             Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley 
             </p>
        </div>
            </div>
        </div>

        <div className="row mt-5 justify-content-center">
             <div className="col-md-6">
             <Link to="/register-your-ecommerce-store" > 
                  <div className="card-select-your-domain">
                    <div className="do-card-both">
                    <img src={DomainCardImg} alt="" />
                    <p>I Offer Services</p>
                    </div>
                   
                </div></Link>
             </div>
             <div className="col-md-6">
             <Link to="/register-your-ecommerce-store" > <div className="card-select-your-domain">
                    <div className="do-card-both">
                   <img src={DomainCardImg} alt="" />
                    <p>I Offer Services</p>
                    </div>
                   
                </div></Link>
             </div>
        </div>

       </div>
      
    </div>
  )
}

export default SelectYourDomainCards