import React from 'react'
import Header from '../../../components/Layout/Header'
import DonateFormSec from './DonateFormSec'
import Footer from '../../../components/Layout/Footer'

function DonateNowForm() {
  return (
    <div>
      <Header/>
      <DonateFormSec/>

      <Footer/>

    </div>
  )
}

export default DonateNowForm
