import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import pak from '../../media/images/Pakistan (PK).png'
import afg from '../../media/images/Afghanistan (AF).png'
import arr from '../../media/images/Vector.png'
import arg from '../../media/images/Argentina (AR).png'

const countries = [
  { name: "Pakistan", flag: pak },
  { name: "Afghanistan", flag: afg },
  { name: "Argentina", flag: arg },
];
const cities = [
  { name: "New York" },
  { name: "Los Angeles" },
  { name: "Chicago"},
];

function RegisterFormEcommerce() {
  const [selectedCountry, setSelectedCountry] = useState(countries[0]); // Default selected country
  const [selectedCity, setSelectedCity] = useState(countries[0]); // Default selected country
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownCityOpen, setDropdownCityOpen] = useState(false);

  const handleSelect = (country) => {
    setSelectedCountry(country);
    setDropdownOpen(false); // Close dropdown after selection
  };
  const handleSelect2 = (city) => {
    setSelectedCity(city);
    setDropdownCityOpen(false); // Close dropdown after selection
  };

    return (
        <div className="register-ecommerce-main-form">
          <div className="container">
    
            <div className="row mt-5 justify-content-center">
                
              <div className="col-md-7">
              <h1 className="form-title">Register Your <br></br>Ecommerce Store</h1>
                <form className="form">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Full Name</label>
                        <input type="text" name="fname" className="form-control ecommerce_name_flied" placeholder="First name" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label></label>
                        <input type="text" name="lname" className="form-control ecommerce_name_flied" placeholder="Last name" />
                      </div>
                    </div>
                  </div>
                  {/* custom drop down */}
                  <div className="custom-dropdown">
                      <label>Country / Region</label>
                      <div
                        className="dropdown-selected"
                        onClick={() => setDropdownOpen(!dropdownOpen)}
                      >
                        <div>
                        <img src={selectedCountry.flag} alt="Selected flag" className="flag-icon" />
                        <span>{selectedCountry.name}</span>
                        </div>

                        <span className="arrow"><img src={arr} /></span> {/* Dropdown arrow */}
                      </div>
                      {dropdownOpen && (
                        <div className="dropdown-options">
                          {countries.map((country, index) => (
                            <div
                              key={index}
                              className="dropdown-option"
                              onClick={() => handleSelect(country)}
                            >
                              <img src={country.flag} alt={`${country.name} flag`} className="flag-icon" />
                              <span>{country.name}</span>
                            </div>
                          ))}
                        </div>
                      )}
                    </div> 
                  {/* <div className="form-group">
                    <label>Country / Region</label>
                    <select className="form-control ecommerce_name_flied">
                      <option> <img src={pak} /> Pakistan</option>
                      <option>  <img src={afg} /> Canada</option>
                      <option>  <img src={arg} /> United Kingdom</option>
                    </select>
                  </div>
     */}


<div className="custom-dropdown">
                      <label>City</label>
                      <div
                        className="dropdown-selected"
                        onClick={() => setDropdownCityOpen(!dropdownCityOpen)}
                      >
                        <div>
                        <span>{selectedCity.name}</span>
                        </div>

                        <span className="arrow"><img src={arr} /></span> {/* Dropdown arrow */}
                      </div>
                      {dropdownCityOpen && (
                        <div className="dropdown-options">
                          {cities.map((cities, index) => (
                            <div
                              key={index}
                              className="dropdown-option"
                              onClick={() => handleSelect2(cities)}
                            >
                              <span>{cities.name}</span>
                            </div>
                          ))}
                        </div>
                      )}
                    </div> 
                  {/* <div className="form-group">
                    <label>City</label>
                    <select className="form-control ecommerce_name_flied">
                      <option>New York</option>
                      <option>Los Angeles</option>
                      <option>Chicago</option>
                    </select>
                  </div> */}
    
                  <div className="form-group">
                    <label>Street Address</label>
                    <input type="text" className="form-control  ecommerce_name_flied"  />
                  </div>
    
                  <div className="form-group">
                    <label>Zip Code</label>
                    <input type="text" className="form-control  ecommerce_name_flied" />
                  </div>
    
                  <div className="form-group">
                    <label>Email</label>
                    <input type="email" className="form-control  ecommerce_name_flied" placeholder="example@example.com" />
                  </div>
    
                  <div className="form-group">
                    <label>Password</label>
                    <input type="password" className="form-control  ecommerce_name_flied" placeholder="Set the login password" />
                  </div>
    
                  <div className="form-group">
                    <label>Confirm Password</label>
                    <input type="password" className="form-control  ecommerce_name_flied" placeholder="Enter the login password again" />
                  </div>
    
                  <div className="form-group">
                    <label>Phone</label>
                    <input type="tel" className="form-control  ecommerce_name_flied" placeholder="123456789" />
                  </div>
    
                 
    
                  <Link to="/email-verfication"><button type="submit" className="btn btn-gradient">Register</button></Link>
                  <div className="form-group form-check">
                    <input type="checkbox" className="form-check-input" id="termsCheckbox" />
                    <label  style={{color:"#000",fontFamily:"Poppins", fontSize:"18px",}} className="form-check-label" htmlFor="termsCheckbox">
                    I agree to (a)<a href='#' style={{color:"#F16522"}}>Free Membership Agreement, </a>(b)<a href='#' style={{color:"#F16522"}}>Terms of Use, and </a>(c)<a href='#' style={{color:"#F16522"}}>Privacy Policy</a>. I agree to receive more information from Happy hearts about its products and services.
                    </label>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      );
    }
    
    export default RegisterFormEcommerce;