import React, { useState } from 'react'
import methew from '../../../../media/images/methewSmall.png'
import nig from '../../../../media/images/nigeriaa.png'
import right from '../../../../media/images/tic.png'
import star from '../../../../media/images/star.svg'
import { Link, useNavigate } from 'react-router-dom'
import Modal from '../../../../components/Layout/Modal'

function PricePreviewTop() {
    const navigate = useNavigate()

    const handleNavigate = () => {
        navigate("/add-service-details", { state: { showModal: true } })
    }

    const handleEditNavigate = () => {
        navigate("/add-service")
    }

    return (
        <div className='container'>
            <div className="service-profile-wrapper">

                <div className="row">
                    <div className="col-lg-6">
                        <div className="service-seller-profile-main">
                            <div className="row">
                                <div className="col-lg-2">
                                    <div className="methew-small-user">
                                        <img src={methew} />

                                    </div>
                                </div>
                                <div className="col-lg-10">
                                    <div className="service-seller-details">
                                        <div className="service-seller-name">
                                            <h4>Mathew</h4>
                                            <img src={nig} />
                                        </div>
                                        <div className="service-seller-ratings">
                                            <img src={star} />
                                            <p>4.9 <span style={{ fontSize: "12px", color: "gray" }}>/5 (78) | </span> 2 <span style={{ fontSize: "12px", color: "gray" }}>orders in process </span></p>
                                        </div>
                                        <button>Top Rated</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="edit-save-preview">
                            <div className="edit-preview-button">
                                <button onClick={handleEditNavigate}>Edit</button>
                            </div>
                            <div className="save-preview-button">
                                <button onClick={handleNavigate}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default PricePreviewTop
