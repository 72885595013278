import React from 'react'
import ShoppingCartCard from './ShoppingCartCard'
import Header from '../../components/Layout/Header'
import ShoppingCartSectionTwo from './ShoppingCartSectionTwo'
import SliderOneDetailPage from '../Feature Product/ProductDetail/slider_other-recomendations/SliderOneDetailPage'
import Footer from '../../components/Layout/Footer'

function ShoppingCart() {
  return (
    <div>
      <Header/>
      <ShoppingCartSectionTwo/>
      <div className='container'>
            <div className="sliderOne">
                <h2>Others recommandations for you</h2>
                <SliderOneDetailPage />
            </div>
      </div>
      <Footer/>
    </div>
  )
}

export default ShoppingCart
