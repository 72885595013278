import React from 'react'
import Header from '../../../../components/Layout/Header'
import Footer from '../../../../components/Layout/Footer'
import NonproFirstSec from './NonproFirstSec'

function Nonprofit() {
  return (
    <div>
       <Header/>
       <NonproFirstSec/>
       <Footer/>

    </div>
  )
}

export default Nonprofit

