import React from 'react'
import s3 from '../../media/images/s3.png'
import Counter from './Counter'
function ShoppingCartCard() {
    return (
        <div className='shopping-cart-card-main'>
            <div className="row">
                <div className="col-lg-3">
                    <div className="shopping-cart-card-image">
                        <img src={s3} />
                    </div>
                </div>
                <div className="col-lg-9">
                    <div className="shpping-card-right">
                        <div className="shopping-cart-card-top">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="shopping-cart-card-top-text">
                                        <h3>Nike Dunk Low</h3>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="shopping-cart-card-top-button">
                                        <button>Remove</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="shopping-cart-card-price">
                        <span style={{color:"hsla(0, 0%, 13%, 1)", fontSize:"29px", fontFamily: "openSansB"}}>Price:</span><h2>$ 38.99 </h2>
                            <h4>+US $29.99</h4>
                        </div>

                        <div className="shopping-cart-card-description">
                            <p>PlayStation 5 Controller Skin High quality vinyl with air channel adhesive <br></br> for easy bubble free install & mess free removal Pressure sensitive.</p>
                        </div>

                        <div className="shopping-cart-card-properties">
                            <div className='d-flex justify-conternt-center align-items-center gap-20'>
                                <div className='color'>Colours:</div>
                                <div>⚫</div>
                            </div>
                            <div className='d-flex justify-conternt-center align-items-center gap-20'>
                                <div className='size'>Size:</div>
                                <div className='size-shp-cart'>M</div>
                            </div>
                        </div>
                        <div className="shopping-cart-card-quantity">
                            <div className='shopping-cart-quantity-description'>
                                <p>International Shipping from United Kingdom</p>
                            </div>

                            <div className='shopping-cart-counter'>
                                <p>Quantity :</p> <Counter/> 
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShoppingCartCard
