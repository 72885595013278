import React, { useState } from 'react'
import emergencycardimg from '../../../media/images/emergencycard.png'
import Toogle from '../../../components/Main/Toogle'
import i from '../../../media/images/i.png';
import visa from '../../../media/images/Tem_Images/visa.svg';
import mastercard from '../../../media/images/Tem_Images/mastercard.svg';
import paypal from '../../../media/images/Tem_Images/paypal.svg';
import discover from '../../../media/images/Tem_Images/discover.svg';
import amex from '../../../media/images/Tem_Images/amex.svg';
import Modal from '../../../components/Layout/Modal';
import right from '../../../media/images/tic.png'
import { Link } from 'react-router-dom';

function DonateFormSec() {
    const [checked, setChecked] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleCloseModal = () => {
        setIsModalVisible(false);
    };

    const handleConfirmClick = () => {
        setIsModalVisible(true);
    }
    return (
        <>
            <div className='donate-form-sec'>
                <div className="container">
                    <div className="row">
                    <div className="col-md-6">
                        <div className="emergency-fund-card">
                            <img src={emergencycardimg} alt="" />
                            <div className="row align-items-center">
                                <div className="col-md-8">
                                    <div className="emergency-fund-text">
                                        <h3>
                                            Donate Emergency Fund
                                        </h3>
                                        <div className="toggle-sys">
                                            <p>One time</p>
                                            <Toogle
                                                isOn={checked}
                                                handleToggle={() => setChecked(!checked)}
                                                colorOne="#EF476F"
                                                colorTwo="#06D6A0"
                                            />
                                            <p>One time</p>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="emergency-fund-dolortext">
                                             <p>30$</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                            <hr style={{width:'85%', margin:'0 auto',paddingBottom:'10px'}} />
                            <div className="col-md-12">
                               <div className="total-donate-price">
                                <p>Total:</p>
                                <p>$30</p>
                               </div>
                            </div>
                            </div>
                        </div>
                       
                    </div>
                    <div className="col-md-6">
                    <div className="with-pay">
                            <div className="payment-container">
                                <h4>Pay with</h4>
                                <p className="mb-2">
                                    <small>Credit or Debit card</small>
                                </p>
                                <p>
                                    <small style={{fontWeight:'500',color:'#848484'}}>Your payments are secured, Your details are confidential.</small>
                                </p>

                                <form>
                                    {/* Card Number */}
                                    <div className="mb-3">
                                      
                                        <input
                                            type="text"
                                            className="form-control input-field"
                                            id="cardNumber"
                                            placeholder="Card Number"
                                        />
                                    </div>

                                    {/* Expiration Date and Security Code */}
                                    <div className="row">
                                        <div className="col-6 mb-3">
                                           
                                            <input
                                                type="text"
                                                className="form-control input-field"
                                                id=""
                                                placeholder="Expiration Date"
                                            />
                                        </div>
                                        <div className="col-6 mb-3">
                                            
                                          <div className="security">
                                          <input
                                                type="text"
                                                className="form-control input-field"
                                                id=""
                                                placeholder="CVV"
                                            />

                                            <img src={i} alt=""  className='img_i' srcset="" />
                                          </div>
                                           

                                        </div>
                                    </div>

                                    {/* First Name and Last Name */}
                                    <div className="row">
                                        <div className="col-6 mb-3">
                                          
                                            <input
                                                type="text"
                                                className="form-control input-field"
                                                id=""
                                                placeholder="First Name"
                                            />
                                        </div>
                                        <div className="col-6 mb-3">
                                         
                                            <input
                                                type="text"
                                                className="form-control input-field"
                                                id=""
                                                placeholder="Last Name"
                                            />
                                        </div>
                                    </div>

                                    {/* Save as Draft Checkbox */}
                                   
                                </form>
                                <div className="img-payment-donate-form">
                                <img src={visa} alt="Visa" />
                                <img src={mastercard} alt="MasterCard" />
                                <img src={paypal} alt="PayPal" />
                                <img src={discover} alt="Discover" />
                                <img src={amex} alt="Amex" />
                                <img src={paypal} alt="PayPal" />
                                <img src={visa} alt="Visa" />
                                <img src={mastercard} alt="MasterCard" />
                                <img src={amex} alt="Amex" />
                                </div>  

                                <div class="donate-and-pay">
                                    <button class="btn btn-donatenow" onClick={handleConfirmClick}>Donate</button>
                                    <p>Remember this card for the future</p>
                                    </div> 
                            </div>
                        </div>
                    </div>
                  </div>
                  <Modal isVisible={isModalVisible} onClose={handleCloseModal}>
                            <div className='modal-content-here'>
                                <div className="modal-success-image">
                                    <img src={right} />
                                </div>
                                <div className="modal-content-title">
                                    <h2>Thank You for Donating!</h2>
                                </div>
                                <div className="modal-content-buttons">
                                    <div class="modal-content-button">
                                        <Link to="/donate-form" style={{ textDecoration: "none" }}> <button onClick={handleCloseModal}>Donate Again</button></Link>
                                    </div>
                                </div>

                            </div>
                        </Modal>
                </div>

            </div>
        </>
    )
}

export default DonateFormSec
