import React from 'react'
import productBanner from '../../media/images/Banners_Mesa de trabajo 1 1 (1).png'

function Productbanner() {
  return (
    <div className='service-landing-page'>
            <img src={productBanner} />      
    </div>
  )
}

export default Productbanner
