import React from 'react'
import methew from '../../../media/images/methewSmall.png'
import nig from '../../../media/images/nigeriaa.png'
import star from '../../../media/images/star.svg'

function ServiceSellerProfileTop() {
    return (
        <div className='container'>
            <div className="service-profile-wrapper">
                
            <div className="row">
                <div className="col-lg-6">
                    <div className="service-seller-profile-main">
                        <div className="row">
                            <div className="col-lg-2">
                                <div className="methew-small-user">
                                    <img src={methew} />

                                </div>
                            </div>
                            <div className="col-lg-10">
                                <div className="service-seller-details">
                                    <div className="service-seller-name">
                                        <h4>Mathew</h4>
                                        <img src={nig} />
                                    </div>
                                    <div className="service-seller-ratings">
                                    <img src={star} />
                                     <p>4.9 <span style={{fontSize:"12px" , color:"gray"}}>/5 (78) | </span> 2 <span style={{fontSize:"12px" , color:"gray"}}>orders in process </span></p>
                                    </div>
                                    <button>Top Rated</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            <div className="col-lg-6">
                <div className="service-seller-availability">
                    <button>Available Right Now</button>
                </div>
            </div>
            </div>

            </div>
        </div>
    )
}

export default ServiceSellerProfileTop
