import React from 'react'
import HeaderTop from '../../../../components/Layout/HeaderTop'
import SetupYourOfficeSectionTwo from './SetupYourOfficeSectionTwo'
import Footer from '../../../../components/Layout/Footer'

function SetupYourOffice() {
  return (
    <div>
      <HeaderTop/>
      <SetupYourOfficeSectionTwo/>
      <Footer/>
    

    </div>
  )
}

export default SetupYourOffice
