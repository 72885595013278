import React from 'react'
import vendorLogo from '../../../media/images/vendorLogo.png'
import hearts from '../../../media/images/heartsss.png'
import share from '../../../media/images/material-symbols_share-outline.png'
import { Link } from 'react-router-dom'

function SuplierStoreTop() {
  return (
    <>
    <div className='container'>
        <div className="suplier-brand-card">
            
      <div className="row mt-4 mb-2">
        <div className="col-lg-2"></div>
        <div className="col-lg-6">
            <div className="save-vendor-text">
                <p>Save this Vendor</p>
            </div>
        </div>
        <div className="col-lg-2"></div>
        <div className="col-lg-2"></div>
      </div>
      {/*sec-mid  */}
      <div className="row">
        <div className="col-lg-2">
            <div className="vendor-logo">
                <img src={vendorLogo} />
            </div>
        </div>
        <div className="col-lg-6">
            <div className="vendor-middle-box">
                <div className="row">
                    <div className="col-lg-10">
                        <div className="vendor-title">
                        <h2>Zhejiang Yonjou Technology Ltd.</h2>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="vendor-box-button">
                            <button>07 <span style={{fontSize:"13px", color:"gray"}}>Years</span></button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-10">
                        <div className="vendor-title-content">
                            <p><span>Main Categories :</span> Headphones, Microphone, Airpods, Chargers </p>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        
                    </div>
                </div>
            </div>
        </div>
        <div className="col-lg-2">
            <div className="vendors-button">
                <button>Contact</button>
            </div>
            <div className="vendors-button">
                <button>Send Inquery</button>
            </div>
        </div>
        <div className="col-lg-2" style={{position:"relative"}}>
            <div className="vendor-share-icons">
                <img src={share} />
            </div>
            <div className="vendor-heart-icons">
                <img src={hearts} />
            </div>
        </div>
      </div>
      </div>
    </div>
     <div className="suplier-store-navigation">
      <div className="container">
        <ul>
            <li>Home</li>
           <Link to="/SuplierStoreProduct"  style={{textDecoration: "none"}}> <li>Product</li></Link>
        </ul>
      </div>
      </div>
    </>
)
}

export default SuplierStoreTop
