import React from 'react'
import Header from '../../../components/Layout/Header'
import SuplierStoreTop from './SuplierStoreTop'
import SuplierStoreBanner from './SuplierStoreBanner'
import SuplierAboutCompany from './SuplierAboutCompany'
import SliderOneDetailPage from '../ProductDetail/slider_other-recomendations/SliderOneDetailPage'
import ReviewComponent from '../ProductDetail/ReviewComponent'
import stars from '../../../media/images/stars.png'
import Footer from '../../../components/Layout/Footer'

function SuplierStoreDetailPAge() {
    const ratings = [
        { label: "Supplier service", score: 5.0 },
        { label: "On-time shipment", score: 5.0 },
        { label: "Product quality", score: 5.0 },
    ];
  return (
    <>
     <Header/>
     <SuplierStoreTop/>
     <SuplierStoreBanner/>
     <SuplierAboutCompany/>
     <div className="container">
     <div className="sliderOne">
        <h2>Products</h2>
        <SliderOneDetailPage />
    </div>
    <div className="row">
                <div className="col-lg-6">
                    <div className="rating-header">
                        <h2>Ratings & Reviews</h2>
                        <img src={stars} />
                    </div>
                    <div className="rating-details-top">
                        <h2>5.0/<span style={{fontSize: "19px" , fontWeight:"400px" , color: "gray"}}>5 Very satisfied</span></h2>
                    </div>
                    <div className="detail-rating-bars">
                        <div className="container mt-4 p-0">
                            {ratings.map((item, index) => (
                                <div
                                    key={index}
                                    className="d-flex justify-content-between align-items-center mb-3"
                                >
                                    <span className="text-secondary">{item.label}</span>
                                    <div className="flex-grow-1 mx-3" style={{ height: "1px", background: "linear-gradient(90deg, #FF512F, #F09819)" }}></div>
                                    <span className="text-dark">{item.score}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">

                </div>
            </div>
            <div className="comments-reviews">
                <div className="coments-filter-tabs">
                    <div class="f-service-detail-page-top-button">
                        <button style={{ fontSize: "12px" , padding: "5px 30px" , fontWeight:"400" }}>All</button>
                    </div>  
                    <div class="f-service-detail-page-top-button">
                        <button style={{ fontSize: "12px" , padding: "5px 30px",  fontWeight:"400" }}>With photos/Videos</button>
                    </div>
                    <div class="f-service-detail-page-top-button">
                        <button style={{ fontSize: "12px" , padding: "5px 30px" , fontWeight:"400" }}>5 Stars (20)</button>
                    </div>
                </div>
                    <ReviewComponent/>
                </div>
                <div className="send-message-supplier-store">
                    <div className="send-message-title-suplier">
                        <h4>Send Message to Supplier</h4>
                    </div>
                    <div className="send-message-input-suplier">
                        <input type='text' placeholder='Write Message.......'/>
                        <button>Send</button>
                    </div>
                </div>
     </div>
     <Footer/>

    </>
  )
}

export default SuplierStoreDetailPAge
