import React from 'react'
import supBanner from '../../../media/images/Rectangle 30164.png'
function SuplierStoreBanner() {
  return (
    <div className='suplier-store-banner'>
      <img src={supBanner} />
    </div>
  )
}

export default SuplierStoreBanner
