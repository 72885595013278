import React from "react";
import Slider from "react-slick";
import left from "../../../media/images/larrow.png";
import right from "../../../media/images/rarrow.png";
import PopularService from "./PopularService";
import { Link } from "react-router-dom";

function ServiceCardSlider() {
  // Custom Previous Arrow Component
  function PrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={`${className} my-custom-prev-arrow-service`} onClick={onClick}>
          <img src={left} style={{width: "30px" , padding: "0px 0px 0px 0px"}}/>
      </div>
    );
  }

  // Custom Next Arrow Component
  function NextArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={`${className} my-custom-next-arrow-service`} onClick={onClick}>
          <img src={right} style={{width: "30px" , padding: "0px 0px 0px 0px"}}/>
          </div>
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
    arrows: true, // Enable custom arrows
    prevArrow: <PrevArrow />, // Attach custom PrevArrow component
    nextArrow: <NextArrow /> // Attach custom NextArrow component
  };

  return (
    <div className="slider-wrapper">
      <div className="slider-container-service">
        <Slider {...settings}>
          <div>
          <Link to="/singleServiceDetail" style={{textDecoration: "none"  , color:"black"}} > <PopularService/></Link>
          </div>
          <div>
          <Link to="/singleServiceDetail" style={{textDecoration: "none"  , color:"black"}} > <PopularService/></Link>
          </div>
          <div>
          <Link to="/singleServiceDetail" style={{textDecoration: "none"  , color:"black"}} > <PopularService/></Link>
          </div>
          <div>
          <Link to="/singleServiceDetail" style={{textDecoration: "none"  , color:"black"}} > <PopularService/></Link>
          </div>
          <div>
          <Link to="/singleServiceDetail" style={{textDecoration: "none"  , color:"black"}} > <PopularService/></Link>
          </div>
          <div>
          <Link to="/singleServiceDetail" style={{textDecoration: "none"  , color:"black"}} > <PopularService/></Link>
          </div>
          <div>
          <Link to="/singleServiceDetail" style={{textDecoration: "none"  , color:"black"}} > <PopularService/></Link>
          </div>
          <div>
          <Link to="/singleServiceDetail" style={{textDecoration: "none"  , color:"black"}} > <PopularService/></Link>
          </div>
          <div>
          <Link to="/singleServiceDetail" style={{textDecoration: "none"  , color:"black"}} > <PopularService/></Link>
          </div>
        </Slider>
      </div>
    </div>
  );
}

export default ServiceCardSlider;
