import React from 'react'
import Header from '../../../../components/Layout/Header'
import MealFormSec from './MealFormSec'

const FormMealNeighbar = () => {
  return (
    <div>
   <Header/>
      <MealFormSec/>
    </div>
  )
}

export default FormMealNeighbar
