import React from 'react'
import ssbanner from '../../../media/images/singleServiceBanner.png'
function SingleServiceBanner() {
  return (
    <>
        <div className="single-service-detail-page-banner">
            <div className="singlie-service-banner-content">
            <h2>Graphic Designing</h2>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley </p>
            </div>
        </div> 
    </>
  )
}

export default SingleServiceBanner
