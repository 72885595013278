import React from 'react'
import ProductCard from './ProductCard'
import group from '../../media/images/Group.svg'
import ifoneee from '../../media/images/Tem_Images/ifoneee.svg'
import product from '../../media/images/Tem_Images/Rectangle 4401.svg'
import { Link } from 'react-router-dom'

function ProductToExplore() {
  return (
    <>
      <div className='container'>
        <div className="product-section">
          <div className="row">

            <div className="product-section-title d-flex justify-content-space-between align-items-center">
                  <div className="col-lg-11 d-flex justify-content-left gap-20">
                    <img src={group} /> <h1>Top Products to explore</h1>
                  </div>
              <div className="col-lg-1">
                <div className="view-all-buttons">
                <Link to="/product-detail" style={{ textDecoration: "none"}}><p>View All</p></Link>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-3">
                <ProductCard image={ifoneee} />
              </div>
              <div className="col-lg-3">
                <ProductCard image={ifoneee} />
              </div>
              <div className="col-lg-3">
                <ProductCard image={ifoneee} />
              </div>
              <div className="col-lg-3">
                <ProductCard image={ifoneee} />
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  )
}

export default ProductToExplore
