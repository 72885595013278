import React from 'react'
import ShoppingCartCard from './ShoppingCartCard'
import ShoppingCartRight from './ShoppingCartRight'

function ShoppingCartSectionTwo() {
  return (
    <div className='container'>
      <div className="ShoppingCartSectionTwo">
        <div className="row">
          <div className="col-lg-8">
            <h2 className='ss-cart-title'>Shopping Cart</h2>
          </div>
          <div className="col-lg-4"></div>
        </div>
      <div className="row">
        <div className="col-lg-8">
            <ShoppingCartCard/>
            <ShoppingCartCard/>
        </div>
        <div className="col-lg-4">
            <ShoppingCartRight/>
        </div>
      </div>
      </div>
    </div>
  )
}

export default ShoppingCartSectionTwo
