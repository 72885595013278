import './media/style/style.css'
import './media/style/style2.css'
import SingUp from './screens/SingUp';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './screens/Login';
import ForgotPassword from './screens/ForgotPassword';
import Opt from './screens/Opt';
import NewPassword from './screens/NewPassword';
import ProductListing from './screens/ProductListing';
import OrganizationListing from './screens/OrganizationListing';
import ServiceListing from './screens/ServiceListing';
import Home from './screens/Home/Home';
import ProductToExploreDetail from './screens/ProductToExploreDetail';
import TopRatedStoresDetailPage from './screens/TopRatedStoresDetailPage';
import ServiceDetailPage from './screens/ServiceDetailPage';
import DonationDetailPage from './screens/DonationDetailPage';
import Service from './screens/Service Flow/Service';
import FeatureProduct from './screens/Feature Product/FeatureProduct';
import ProductDetail from './screens/Feature Product/ProductDetail/ProductDetail';
import ShoppingCart from './screens/AddToCartFlowForProduct/ShoppingCart';
import CheckOut from './screens/Checkout Flow/CheckOut';
import OrderTracking from './screens/Order Tracking/OrderTracking';
import AddBillingDetails from './screens/AddBillingDetails/AddBillingDetails';
import PopularService from './screens/Service Flow/PopularServiceFlow/PopularService';
import ServiceProvider from './screens/Service Flow/ServiceProviderFlow/ServiceProvider';
import ServiceCatagoryDetailPage from './screens/Service Flow/ServiceCatagoryDetailPage/ServiceCatagoryDetailPage';
import ServiceBillingPage from './screens/Service Flow/ServiceBillingPage';
import ServiceBillingDetailPage from './screens/Service Flow/ServiceBillingDetailPage';
import SelectYourDomain from './screens/SelectYourDomain';
import RegisterYourEcommerceStore from './screens/RegisterYourEcommerceStore';
import EmailVerfication from './components/Main/EmailVerfication';
import PackagesBecomeSeller from './screens/PackagesBecomeSeller';
import BuyPackage from './screens/BuyPackage';
import FundingCycle from './screens/Donations Flow/Donation Funding Cycle/FundingCycle';
import DonationEvent from './screens/Donations Flow/Donations Recent Event/DonationEvent';
import SingleServiceDetail from './screens/Service Flow/Single Service Detail page/SingleServiceDetail';
import SuplierStoreDetailPAge from './screens/Feature Product/SuplierStore/SuplierStoreDetailPage';
import SuplierStoreProductPage from './screens/Feature Product/Suplier Store Product/SuplierStoreProductPage';
import DonationHome from './screens/DonationsHomeFlow/DonationHome';
import ServiceSellProfile from './screens/Service Flow/Service Sell Detail page/ServiceSellProfile';
import ConfirmAppointment from './screens/Service Flow/Confirm Appointment/ConfirmAppointment';

import DonationNow from './screens/Donations Flow/Donation Now/DonationNow';
import DonationBeachClean from './screens/Donations Flow/Donation beach clean/DonationBeachClean';
import DonateNowForm from './screens/Donations Flow/Donate Now Form/DonateNowForm';
import BecomeVolunteer from './screens/Donations Flow/Become a Volunteer/BecomeVolunteer';
import DonationFoodDrive from './screens/Donations Flow/Donation Food Drive/DonationFoodDrive';
import DonationFundingCycleAll from './screens/Donations Flow/DonationFundingCycleAll/DonationFundingCycleAll';
import ServiceDashboardMain from './dashboards/Seller/Service/ServiceMainPage/ServiceDashboardMain';
import SetupYourOffice from './dashboards/Seller/Service/SetupYourOffice/SetupYourOffice';
import AddService from './dashboards/Seller/Service/AddService/AddService';
import Nonprofit from './dashboards/Seller/Donation/NonProfitScreen/Nonprofit';
import NonProfitForm from './dashboards/Seller/Donation/NonProfitScreen/NonProfitForm';
import BuymonthlySubcribtion from './dashboards/Seller/Donation/BuyMonthlySub/BuymonthlySubcribtion';
import MealNeighbaForm from './dashboards/Seller/Donation/MealstoNeighbors/MealNeighbaForm';
import AddFrequentlyQuestions from './dashboards/Seller/Service/FrequetQuestionsAdd/AddFrequentlyQuestions';
import PreviewFixedPrice from './dashboards/Seller/Service/FrequetQuestionsAdd/PreviewFixedPrice';
import PreviewFixedPrice2 from './dashboards/Seller/Service/FrequetQuestionsAdd/PreviewFixedPrice2';


function App() {
  return (
    // commenttt
    <>
    <Router>
      <Routes>
        <Route path="/" element={<Home/> } />

        {/* Login Registration */}
        <Route path="/register" element={<SingUp/> } />
        <Route path="/login" element={<Login/> } />
        <Route path="/forgotPassword" element={<ForgotPassword/> } />
        <Route path="/opt" element={<Opt/> } />
        <Route path="/newpassword" element={<NewPassword/> } />

        {/* Home Routes */}
        <Route path="/bussiness-product" element={<ProductListing/> } />
        <Route path="/bussiness-service" element={<ServiceListing/> } />
        <Route path="/bussiness-organization" element={<OrganizationListing/> } />
        <Route path="/product-detail" element={<ProductToExploreDetail/> } />
        <Route path="/store-detail" element={<TopRatedStoresDetailPage/> } />
        <Route path="/service-detail" element={<ServiceDetailPage/> } />
        <Route path="/donation-detail" element={<DonationDetailPage/> } />

        {/* service flow */}
        <Route path="/service" element={<Service/> } />
        <Route path="/popularService" element={<PopularService/> } />
        <Route path="/serviceProvider" element={<ServiceProvider/> } />
        <Route path="/serviceCatagory/:tab" element={<ServiceCatagoryDetailPage/> } />
        <Route path="/serviceBilling" element={<ServiceBillingPage/> } />
        <Route path="/serviceBillingDetail" element={<ServiceBillingDetailPage/> } />
        <Route path="/singleServiceDetail" element={<SingleServiceDetail/> } />
        <Route path="/Service-Seller-Profile" element={<ServiceSellProfile/> } />
        <Route path="/confirmAppointment" element={<ConfirmAppointment/> } />

        {/* Feature Product Flow */}
        <Route path="/featureProduct" element={<FeatureProduct/> } />
        <Route path="/productDetail" element={<ProductDetail/> } />
        <Route path="/ShoppingCart" element={<ShoppingCart/> } />
        <Route path="/CheckOut" element={<CheckOut/> } />
        <Route path="/orderTracking" element={<OrderTracking/> } />
        <Route path="/addBillingDetails" element={<AddBillingDetails/> } />
        <Route path="/SuplierStore" element={<SuplierStoreDetailPAge/> } />
        <Route path="/SuplierStoreProduct" element={<SuplierStoreProductPage/> } />


        {/* Become seller flow */}
        <Route path="/select-your-domain" element={<SelectYourDomain/> } />
        <Route path="/register-your-ecommerce-store" element={<RegisterYourEcommerceStore/> } />
        <Route path="/email-verfication" element={<EmailVerfication/> } />
        <Route path="/packages-become-seller" element={<PackagesBecomeSeller/> } />
        <Route path="/buy-package" element={<BuyPackage/> } />

        {/* Donations */}

        <Route path="/donation-now" element={<DonationNow/> } />
        <Route path="/funding-cycle" element={<FundingCycle/> } />
        <Route path="/funding-cycle-all" element={<DonationFundingCycleAll/> } />
        <Route path="/donation-event" element={<DonationEvent/> } />
        <Route path="/donation-event" element={<DonationEvent/> } />
        <Route path="/donation-beach-clean" element={<DonationBeachClean/> } />
        <Route path="/donate-form" element={<DonateNowForm/> } />
        <Route path="/become-a-volunteer" element={<BecomeVolunteer/> } />
        <Route path="/donation-food-drive" element={<DonationFoodDrive/> } />

         {/* Become a seller Flow */}
        <Route path="/become-seller" element={<DonationHome/> } />
        <Route path="/select-your-domain" element={<SelectYourDomain/> } />
        <Route path="/register-your-ecommerce-store" element={<RegisterYourEcommerceStore/> } />
        <Route path="/email-verfication" element={<EmailVerfication/> } />
        <Route path="/packages-become-seller" element={<PackagesBecomeSeller/> } />
        <Route path="/buy-package" element={<BuyPackage/> } />
        

        {/* Donation Flow */}
        <Route path="/donation-event" element={<DonationEvent/> } />
        <Route path="/funding-cycle" element={<FundingCycle/> } />


      {/*------------------------------------------------------------------------Dashboards--------------------------------------------------------------------------- */}
               {/* Get Donation Dashboard */}

            <Route path="/non-profit" element={<Nonprofit/> } />
            <Route path="/non-profit-form" element={<NonProfitForm/> } />
            <Route path="/buy-monthly-subcribtion" element={<BuymonthlySubcribtion/> } />
            <Route path="/form-meal-neighbar" element={<MealNeighbaForm/> } />



        {/* ------------------------------------------------------------------------Dashboards--------------------------------------------------------------------------- */}
          {/* Seller-Side Service Dashboard */}
          <Route path="/service-dashboard" element={<ServiceDashboardMain/> } />
          <Route path="/setup-your-office" element={<SetupYourOffice/> } />
          <Route path="/add-service" element={<AddService/> } />
          <Route path="/add-service-details" element={<AddFrequentlyQuestions/> } />
          <Route path="/preview-details" element={<PreviewFixedPrice/> } />
          <Route path="/preview-details2" element={<PreviewFixedPrice2/> } />





     </Routes>
    </Router>
    </>
  );
}

export default App;
