import React from 'react'
import Header from '../../../../components/Layout/Header'
import BuySubFrom from './BuySubFrom'
import Footer from '../../../../components/Layout/Footer'

const BuymonthlySubcribtion = () => {
  return (
    <div>
      <Header/>
      <BuySubFrom/>
      <Footer/>
    </div>
  )
}

export default BuymonthlySubcribtion
