import React, { useEffect, useState } from 'react'
import DonationBannerSec from '../DonationBannerSec'
import FundingCycleHeader from '../FundingCycleHeader'
import Header from '../../../components/Layout/Header'
import image2 from "../../../media/images/Tem_Images/donationCard.svg";
import FundingNav from '../FundingNav'
import DonationCard from '../../../components/Main/DonationCard'
import Pagination from '../../../components/Main/Pagination';
import Footer from '../../../components/Layout/Footer';

function DonationFundingCycleAll() {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 8;
    const totalItems = 150; // Total number of products

    // Generate dummy products for display
    const products = Array.from({ length: totalItems }, (_, i) => ({
        id: i + 1,
        name: `Product ${i + 1}`,
        image: image2,
    }));


    useEffect(() => {
        // Scroll to top when the page loads
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);


    // Calculate the products to display for the current page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const displayedProducts = products.slice(startIndex, endIndex);

    return (
        <div>
            <Header />
            <FundingCycleHeader />
            <DonationBannerSec />
            <FundingNav />
            <div className='container'>
                <div className="Donation-Funding-Cycle-All">
                    <div className="row">

                        {displayedProducts.map((product) => (
                            <div key={product.id} className="col-lg-3">
                                <div className="donation-detail-page-card-wrapper">
                                    <DonationCard image={product.image} />
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='pagination-wrapper-detail-page'>
                        <Pagination
                            totalItems={totalItems}
                            itemsPerPage={itemsPerPage}
                            onPageChange={setCurrentPage}
                        />
                    </div>
                </div>

            </div>
            <Footer/>
        </div>
    )
}

export default DonationFundingCycleAll
